import { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet, useNavigate } from 'react-router-dom';
import { useClienteContext } from '../../../contexts/ClienteContext';
import { useEmpresaContext } from '../../../contexts/EmpresaContext';
import Navbar from '../Navbar/Navbar';
import MensajeToken from '../MensajeToken/MensajeToken';
import PerfilCliente from '../PerfilCliente/PerfilCliente';



export default function LayoutCliente() {

  const sucursal = localStorage.getItem('sucursal')


  const navigate = useNavigate()

  const { addCliente } = useClienteContext()
  const { addEmpresa } = useEmpresaContext()

  const [mensajeError, setMensajeError] = useState('')
  const [toggleMenu, setToggleMenu] = useState(false);

  const [isModalOpenPerfil, setIsModalOpenPerfil] = useState(false);


  useEffect(() => {
    getCliente()

  }, [])

  const getCliente = async () => {
    try {
      // Obtén el token de localStorage
      const token = localStorage.getItem('auth_cliente');
      if (!token) {
        navigate('/login');
      }

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/home/${sucursal}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.datosCliente.datosCliente)
      addCliente(response.data.datosCliente.datosCliente);

      // PARA GUARDAR LOS DATOS DE LA EMPRESA EN EL CONTEXTO
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/empresa/${sucursal}`)
      addEmpresa(res.data[0])


    } catch (error) {


      setMensajeError(error.response?.data?.message || error.message);

      switch (error.response?.data?.message) {
        case 'El tiempo ha expirado. Vuelva a iniciar sesion':
          setToggleMenu(!toggleMenu)
          setTimeout(() => {
            localStorage.removeItem('auth_cliente');
            localStorage.removeItem('sucursal')
            localStorage.removeItem('admin')
            navigate("/login")
          }, 10000);
          break;
        case 'Acceso denegado. Token no proporcionado.':
          navigate("/login")
          break;
        default:
          break;
      }
    }
  }

  const logout = async () => {
    try {
      localStorage.removeItem('auth_cliente');
      localStorage.removeItem('sucursal')
      localStorage.removeItem('admin')

      navigate('/login');
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };


  return (

    <div className="h-screen flex flex-col">
      <div className="flex-shrink-0">
        <Navbar logout={logout} setIsModalOpenPerfil={setIsModalOpenPerfil} />
      </div>


      <Outlet />


      {toggleMenu && <MensajeToken mensajeError={mensajeError} logout={logout} />}

      {isModalOpenPerfil && <PerfilCliente setIsModalOpenPerfil={setIsModalOpenPerfil} />}


    </div>
  );
}