import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function CargarArchivo({ clave, handleCloseModal, handleArchivoCargado }) {
  const sucursal = localStorage.getItem('sucursal');
  const [selectedFile, setSelectedFile] = useState(null);

  // Maneja el cambio de archivo
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Convierte un archivo a Base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Maneja el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error("Por favor, seleccione un archivo.", {
        position: "top-center",
        autoClose: 3000,
      }); // Muestra un toast de error
      return;
    }

    try {
      // Convierte el archivo a Base64
      const base64 = await convertFileToBase64(selectedFile);

      // Crea el payload
      const payload = {
        claveRemito: clave, // Agrega clave
        archivo: base64, // Archivo en Base64
      };

      // Envía los datos al endpoint usando axios
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/archivoremito/${sucursal}`, payload);

      if (response.status === 201) {
        toast.success("Archivo subido con éxito.", {
          position: "top-center",
          autoClose: 3000,
        }); // Muestra un toast de éxito
        handleCloseModal(); // Cierra el modal al finalizar
        handleArchivoCargado(); // Llama a la función para actualizar la tabla
      }

    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Maneja el error de archivo duplicado
        toast.error("El remito ya contiene un arhivo adjunto.", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        console.error("Error:", error);
        toast.error("Ocurrió un error al subir el archivo.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50" onClick={handleCloseModal}>
      <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-md mx-4" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-xl font-semibold mb-4 text-center">CARGAR ARCHIVO (PDF o Imagen)</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="file"
            id="fileUpload"
            accept="image/*,.pdf"
            onChange={handleFileChange}
            className="block w-full text-md p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
          />
          <div className="flex justify-between mt-6">
            <button onClick={handleCloseModal} type="button" className="bg-red-500 w-36 text-white px-4 py-1 rounded-sm hover:bg-red-600 transition font-semibold">
              CANCELAR
            </button>
            <button type="submit" className="bg-blue-500 w-36 text-white px-4 py-1 rounded-sm hover:bg-blue-600 transition font-semibold">
              SUBIR
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
