import imagenfondoIntegralSur from '../../../images/logo-sur.jpg'
import imagenFondoIntegral from '../../../images/logo-nuevo-srl.jpg'


export default function HomeCliente() {

    const sucursal = localStorage.getItem('sucursal')

    // Determina la imagen de fondo según la sucursal
    const fondo = sucursal === 'sur' ? imagenfondoIntegralSur : imagenFondoIntegral;

    return (
        <>
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <img className='' src={fondo} alt='Logo Sucursal' />
                {
                    sucursal !== 'sur' && (
                        <p className='font-bold mt-2 text-xl'>SUCURSAL {sucursal === 'buch' ? 'BUCHARDO' : sucursal === 'val' ? 'VILLA VALERIA' : sucursal === 'lab' ? 'LABOULAYE' : sucursal == 'ser' ? 'SERRANO' : sucursal == 'mel' ? 'MELO' : sucursal == 'ita' ? 'PUEBLO ITALIANO' : ''}</p>
                    )
                }
            </div>

            <div className='bg-black p-2'>
                <p className='text-center text-black'>Integral Agropecuaria S.R.L</p>
            </div>
        </>
    )
}


