import React from "react";
import axios from "axios";
import { toast } from "react-toastify";


export default function EliminarArchivo({ idArchivo, handleCloseModalEliminar, handleArchivoEliminado }) {
    const sucursal = localStorage.getItem("sucursal");

    const handleEliminarArchivo = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/archivoremito/${sucursal}/${idArchivo}`);
            if (response.status === 200) {
                toast.success("¡Eliminando Archivo correctamente!", {
                    position: "top-center",
                    autoClose: 3000,
                });
                handleCloseModalEliminar(); // Cerrar el modal tras eliminar el archivo
                handleArchivoEliminado(); // Actualizar la tabla 
            } else {
                throw new Error("Error inesperado al eliminar el archivo");
            }
        } catch (error) {
            toast.error("Error al eliminar el archivo. Intenta nuevamente.", {
                position: "top-center",
                autoClose: 3000,
            });
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center">
            <div className="bg-white rounded-sm p-6 w-96">
                <h2 className="text-lg text-center font-bold mb-4">¿DESEA ELIMINAR EL ARCHIVO?</h2>
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleCloseModalEliminar}
                        className="px-4 py-1 bg-gray-300 rounded-sm hover:bg-gray-400 font-semibold"
                    >
                        CANCELAR
                    </button>
                    <button
                        onClick={handleEliminarArchivo}
                        className="px-4 py-1 bg-red-600 text-white rounded-sm hover:bg-red-700 font-semibold"
                    >
                        ELIMINAR
                    </button>
                </div>
            </div>
        </div>
    );
}
